var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-root loginPage"},[_c('div',{staticClass:"login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white",class:{
      'login-signin-on': this.state == 'signin',
      'login-forgot-on': this.state == 'forgot',
    },attrs:{"id":"kt_login"}},[_c('div',{staticClass:"login-aside d-flex flex-column flex-row-auto",staticStyle:{"background-color":"#f2c98a"}},[_vm._m(0),_c('div',{staticClass:"aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",style:({ backgroundImage: `url(${_vm.backgroundImage})` })})]),_c('div',{staticClass:"login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"},[_c('div',{staticClass:"d-flex flex-column-fluid flex-center"},[_c('v-app',{staticClass:"login-page"},[_c('div',{staticClass:"login-form login-signin"},[_c('v-form',{ref:"loginForm",staticClass:"form",attrs:{"id":"kt_login_signin_form","lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0),_c('div',{staticClass:"pb-13 pt-lg-0 pt-5"},[_c('h3',{staticClass:"font-weight-bolder text-dark font-size-h4 font-size-h1-lg"},[_vm._v(" Welcome to Genic Teams ")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v("Email")]),_c('div',{attrs:{"id":"email-input-group","label":"","label-for":"email-input"}},[_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"rules":[
                      _vm.validateRules.required(_vm.form.email, 'Email'),
                      _vm.validateRules.validEmail(_vm.form.email, 'Email'),
                    ],"autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubmitLogin.apply(null, arguments)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-flex justify-content-between mt-n5"},[_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark pt-5"},[_vm._v("Password")]),_c('a',{staticClass:"text-primary link font-size-h6 font-weight-bolder text-hover-primary pt-5",attrs:{"id":"kt_login_forgot","link":""},on:{"click":function($event){return _vm.showForm('forgot')}}},[_vm._v("Forgot Password ?")])]),_c('div',{attrs:{"id":"password-input-group","label":"","label-for":"password-input"}},[_c('v-text-field',{attrs:{"disabled":_vm.isDisabled,"rules":[
                      _vm.validateRules.required(_vm.form.password, 'Password'),
                    ],"autocomplete":"off","type":"password"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onSubmitLogin.apply(null, arguments)}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)]),_c('div',{staticClass:"pb-lg-0 pb-5"},[_c('button',{ref:"kt_login_signin_submit",staticClass:"btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3",attrs:{"type":"button","disabled":_vm.isDisabled || !_vm.formValid},on:{"click":_vm.onSubmitLogin}},[_vm._v(" Sign In ")])])])],1),_c('div',{staticClass:"login-form login-forgot"},[_c('form',{ref:"kt_login_forgot_form",staticClass:"form",attrs:{"id":"kt_login_forgot_form","novalidate":"novalidate"}},[_c('div',{staticClass:"pb-13 pt-lg-0 pt-5"},[_c('h3',{staticClass:"font-weight-bolder text-dark font-size-h4 font-size-h1-lg"},[_vm._v(" Forgotten Password ? ")]),_c('p',{staticClass:"text-muted font-weight-bold font-size-h4"},[_vm._v(" Enter your email to reset your password ")])]),_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",attrs:{"autocomplete":"off","name":"email","placeholder":"Email","type":"email"}})]),_c('div',{staticClass:"form-group d-flex flex-wrap pb-lg-0"},[_c('button',{staticClass:"btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4",attrs:{"id":"kt_login_forgot_submit","type":"button"}},[_vm._v(" Submit ")]),_c('button',{staticClass:"btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3",attrs:{"id":"kt_login_forgot_cancel","type":"button"},on:{"click":function($event){return _vm.showForm('signin')}}},[_vm._v(" Cancel ")])])])])])],1),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column-auto flex-column pt-lg-40 pt-15"},[_c('a',{staticClass:"text-center mb-10",attrs:{"href":"#"}},[_c('img',{staticClass:"max-h-70px",attrs:{"alt":"","src":"media/logos/t9_logo.webp"}})]),_c('h3',{staticClass:"font-weight-bolder text-center font-size-h4 font-size-h1-lg",staticStyle:{"color":"#986923"}},[_vm._v(" Discover Amazing Genic Teams "),_c('br'),_vm._v("with great build tools ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"},[_c('a',{staticClass:"text-primary font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Terms")]),_c('a',{staticClass:"text-primary ml-10 font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Plans")]),_c('a',{staticClass:"text-primary ml-10 font-weight-bolder font-size-h5",attrs:{"href":"#"}},[_vm._v("Contact Us")])])
}]

export { render, staticRenderFns }